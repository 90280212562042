// i18next-extract-mark-ns-start customer-defence-form

import {Article} from 'components/Article';
import {Button} from 'components/Button';
import {Field} from 'components/Field';
import {SEO} from 'components/SEO';
import {Formik, FormikHelpers} from 'formik';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React, {useEffect, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

type FormValues = {
  name: string;
  documentNumber: string;
  address: string;
  email: string;
  phone: string;
  details: string;
  recaptchaToken: string;
};

type RequestParams = {
  method?: string;
  headers?: any;
  body?: any;
};
const request = async (url: string, {method = 'GET', headers, body}: RequestParams = {}) => {
  if (method === 'POST') headers = {'Content-Type': 'application/json', ...headers};
  const res = await fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  });
  const result = await res.json();
  if (!res.ok) throw result || new Error(res.statusText);
  return result;
};

const Page: React.FC = () => {
  const {t} = useI18next();
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (!captchaRef.current) return;
    setTimeout(() => {
      // @ts-ignore
      const container: HTMLDivElement = captchaRef.current.captcha;
      const iframe = container.getElementsByTagName('iframe')[0];
      if (!iframe) return;
      iframe.tabIndex = -1;
    }, 500);
  }, []);

  const handleSubmit = async (
    values: FormValues,
    {setSubmitting, setErrors}: FormikHelpers<FormValues>
  ) => {
    try {
      await request('https://api.monei.com/report-client-defence', {
        method: 'POST',
        body: values
      });
      setSubmitting(false);
      setSubmitted(true);
    } catch (error) {
      setSubmitting(false);
      setErrors({details: error.message});
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('please provide your name')),
    documentNumber: yup.string().required(t('please provide your ID/Passport #')),
    address: yup.string().required(t('please provide your address')),
    email: yup.string().required(t('please provide your email')),
    phone: yup.string().required(t('please provide your phone number')),
    details: yup.string().required(t('please provide details of complaint'))
  });

  return (
    <Article style={{minHeight: 1000}}>
      <SEO title={t('Customer Defense Form')} />
      <Trans parent="h1" style={{fontSize: 30}}>
        Customer Defense Form
      </Trans>
      {isSubmitted ? (
        <Trans parent="p">Your complaint has been recorded. Thank you!</Trans>
      ) : (
        <Formik
          initialValues={{
            name: '',
            documentNumber: '',
            address: '',
            email: '',
            phone: '',
            details: '',
            recaptchaToken: ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({isSubmitting, setSubmitting, handleSubmit, setFieldValue}) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!captchaRef.current) return;
                  captchaRef.current.execute();
                }}>
                <Field as="input" name="name" placeholder={t('Name')} />
                <Field as="input" name="documentNumber" placeholder={t('ID/Passport #')} />
                <Field as="input" name="address" placeholder={t('Address')} />
                <Field as="input" type="email" name="email" placeholder={t('Email')} />
                <Field as="input" type="tel" name="phone" placeholder={t('Phone number')} />
                <Field
                  as="textarea"
                  name="details"
                  rows="5"
                  placeholder={t('Detail your complaint as much as possible')}
                />
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={'6LejBMYUAAAAAIqU0f3BtAMzBqy70HIIbUuKjqvT'}
                  tabindex={-1}
                  size="invisible"
                  onErrored={() => {
                    setSubmitting(false);
                  }}
                  onExpired={() => {
                    setSubmitting(false);
                  }}
                  onChange={(captcha) => {
                    setFieldValue('recaptchaToken', captcha);
                    handleSubmit();
                    captchaRef?.current?.reset();
                  }}
                />
                <Button type="submit" variant="light" block loading={isSubmitting}>
                  <Trans>Submit complaint</Trans>
                </Button>
              </form>
            );
          }}
        </Formik>
      )}
    </Article>
  );
};

export default Page;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "customer-defence-form"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
